<template>
  <div class="login">
    <wave />
    <div class="banner">
      <div class="banner_title">卖家中心</div>
      <div class="banner_title_desc">数字链接 智慧交易</div>
      <el-carousel height="115px" arrow="never">
        <el-carousel-item v-for="item in bannerList" :key="item.title">
          <div>{{ item.title }}</div>
          <div>{{ item.content }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="login_form" v-if="currentShow === 'LOGIN'">
      <template v-if="!companyList || companyList.length <= 1">
        <div
          class="font-weight-bold mb-1"
          style="
            margin-bottom: 20px;
            text-align: center;
            font-size: 24px;
            color: #242832;
          "
        >
          卖家中心登录
        </div>
        <el-tabs v-model="loginType">
          <el-tab-pane label="密码登录" name="PASSWORD">
            <el-form
              :model="pwdLoginForm"
              :rules="pwdLoginFormRules"
              ref="pwdLoginForm"
            >
              <el-form-item prop="account">
                <el-input
                  placeholder="请输入手机号"
                  name="account"
                  prefix-icon="el-icon-mobile-phone"
                  v-model="pwdLoginForm.account"
                  maxlength="11"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  placeholder="请输入登录密码"
                  name="code"
                  prefix-icon="el-icon-lock"
                  v-model="pwdLoginForm.code"
                  type="password"
                  show-password
                >
                </el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="短信登录" name="CAPTCHA">
            <el-form
              :model="phoneLoginForm"
              :rules="phoneLoginFormRules"
              ref="phoneLoginForm"
            >
              <el-form-item prop="account">
                <el-input
                  placeholder="请输入手机号"
                  v-model="phoneLoginForm.account"
                  maxlength="11"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  placeholder="请输入短信验证码"
                  v-model="phoneLoginForm.code"
                >
                  <i slot="prefix" class="el-input__icon el-icon-message"></i>
                  <span
                    slot="append"
                    class="cursor-pointer"
                    @click="handleCheckCode"
                    >{{ loginMsgTip }}</span
                  >
                </el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div class="login_form_msg font-small">{{ loginErrorMsg }}</div>
        <el-button
          type="primary"
          class="w-100"
          @click="handleLogin"
          :loading="isLogining"
          >登录</el-button
        >
      </template>
      <div v-else>
        <div class="company_list">
          <div
            class="company_list_item"
            v-for="c in companyList"
            :key="c.supplierId"
            @click="switchCompany(c)"
          >
            <div>
              {{ c.supplierName }}
            </div>
            <div>
              <span>{{ c.name }}</span>
              <span class="ml-1 mr-1">|</span>
              <span>{{ c.supplierCertifiedFlag ? "是" : "否" }}</span>
            </div>
          </div>
        </div>
        <el-row type="flex" justify="space-between">
          <span
            class="text-primary cursor-pointer font-normal"
            @click="companyList = []"
            >返回</span
          >
        </el-row>
      </div>
    </div>
    <div class="login_footer">
      <span>Copyright©2021 小紫科技. </span>
      <a target="_blank" href="http://beian.miit.gov.cn/"
        >琼ICP备2023003828号</a
      >
    </div>
    <!-- <verifition mode="pop"
      captchaType="blockPuzzle"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    /> -->
    <!-- @success="handleCheckCodeSuccess" -->
  </div>
</template>
<script>
// import { mapActions, mapMutations, mapGetters } from 'vuex';
import { phoneLogin, sendCaptcha } from '@/api/login';
import Wave from '@/components/wave';
import { getUserSupplierList, switchSupplier } from '@/api/userCenter';
// import Verifition from '@/components/verifition';
// import UserApi from '@/api/user';

export default {
  name: 'LOGIN',
  components: {
    Wave,
    // Verifition,
  },
  data () {
    return {
      bannerList: [
        {
          title: '合同管理',
          content: ' 商机、合同即时推送，客户需求可视化管理',
        },
        {
          title: '发货管理',
          content: '商流、物流实时查看，发货、签收精准跟踪',
        },
        {
          title: '对账管理',
          content: '月对账，日对账、库存、一键导出，轻松搞定',
        },
      ],
      agreementList: [
        {
          title: '《服务条款》',
          href: `${process.env.VUE_APP_HOST}xzfwtk.html`,
        },
        {
          title: '《隐私协议》',
          href: `${process.env.VUE_APP_HOST}xzysxy.html`,
        },
      ],
      loginType: 'PASSWORD',
      currentShow: 'LOGIN',
      pwdLoginForm: {
        account: '',
        code: '',
      },
      pwdLoginFormRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        code: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      phoneLoginForm: {
        account: '',
        code: '',
      },
      phoneLoginFormRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      loginMsgTip: '发送短信验证码',
      isLogining: false,
      loginErrorMsg: '',
      registerMsgTip: '发送短信验证码',
      isRegistering: false,
      companyList: [],
    };
  },
  methods: {
    handleLogin () {
      if (this.loginType === 'PASSWORD') {
        this.$refs.pwdLoginForm.validate((valid) => {
          if (valid) {
            phoneLogin({
              phone: this.pwdLoginForm.account,
              code: this.pwdLoginForm.code,
              type: this.loginType,
            }).then((res) => {
              this.detailResult(res);
              this.getUserSupplierList(res);
            });
          }
        });
      } else {
        this.$refs.phoneLoginForm.validate((valid) => {
          if (valid) {
            phoneLogin({
              phone: this.phoneLoginForm.account,
              code: this.phoneLoginForm.code,
              type: this.loginType,
            }).then((res) => {
              this.getUserSupplierList(res);
            });
          }
        });
      }
    },
    detailResult (res) {
      if (res.heads.code === 301) {
        this.loginErrorMsg = `密码已累计输入错误${res.body.passwordWrongTimes}次，累计输入错误20次后账户将被锁定`;
      } else {
        this.loginErrorMsg = '';
      }
    },
    getUserSupplierList (res) {
      if (res.heads.code === 301) {
        this.$JZShowAlert(res.heads.message, 'error');
        return false;
      }
      localStorage.setItem('token', res.body.token);
      this.$store.dispatch('changeUserInfo', res.body.tokenInfo);
      getUserSupplierList({ pageFlag: false, deleteFlag: false }).then(
        (res) => {
          let companyList = res.body.list || [];
          this.$store.dispatch('changeAllCompanyLists', companyList);
          let defaultFlagCompanyList = companyList.filter(
            (item) => item.defaultFlag,
          );
          defaultFlagCompanyList = defaultFlagCompanyList.length
            ? defaultFlagCompanyList
            : companyList;
          if (defaultFlagCompanyList) {
            let supplier = defaultFlagCompanyList[0];
            this.$store.dispatch(
              'changeCurrentSupplierId',
              supplier.supplierId,
            );
            this.$store.dispatch('changeUserInfo', supplier);
            this.$router.push({
              name: 'HOME',
            });
          }
        },
      );
    },
    handleCheckCode () {
      if (this.currentShow === 'LOGIN') {
        if (this.loginMsgTimerTick > 0) {
          return;
        }
        this.$refs.phoneLoginForm.validateField('account', (valid) => {
          !valid && this.sendCaptcha();
        });
      } else if (this.currentShow === 'REGISTER') {
        if (this.registerMsgTimerTick > 0) {
          return;
        }
        this.$refs.registerForm.validateField(
          'ledgerApplicantPhone',
          (valid) => {
            !valid && this.sendCaptcha();
          },
        );
      }
    },
    sendCaptcha () {
      let phone = this.phoneLoginForm.account;
      sendCaptcha({ phone, captchaType: 'LOGIN' }).then(() => {
        if (!this.loginMsgTimerId) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            this.loginMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = '';
              this.loginMsgTip = '获取验证码';
            }
          }, 1000);
        }
      });
    },
    // tenant
    switchCompany (supplier) {
      switchSupplier({ supplierId: supplier.supplierId }).then((res) => {
        this.$store.dispatch('changeCurrentSupplierId', supplier.supplierId);
        this.$store.dispatch('changeUserInfo', res.body);
        this.$router.push({
          name: 'HOME',
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.login {
  overflow: hidden;
  height: 100%;
  width: 100%;

  .banner {
    position: absolute;
    left: 100px;
    top: 50%;
    width: 600px;
    transform: translateY(-50%);

    .banner_title {
      color: #ffffff;
      font-size: 48px;
      line-height: 1;
      letter-spacing: 12px;
    }

    .banner_title_desc {
      padding-top: 8px;
      padding-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 12px;
      color: rgba(255, 255, 255, 0.6);
    }

    .el-carousel {
      .el-carousel__item {
        background: transparent;

        > div:first-child {
          font-weight: 400;
          color: #ffffff;
          font-size: 24px;
          line-height: 1;
        }

        > div:last-child {
          padding-top: 12px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          line-height: 32px;
        }
      }

      /deep/.el-carousel__indicators {
        left: 0;
        bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        transform: none;

        > .el-carousel__indicator {
          padding-top: 0;
          padding-bottom: 0;

          &:not(:first-child) {
            padding-left: 6px;
            padding-right: 6px;
          }
        }
      }
    }
  }

  .login_form,
  .modify_form,
  .register_form {
    position: absolute;
    top: 50%;
    right: 88px;
    width: 348px;
    transform: translateY(-50%);

    // left:50%;
    // width: 388px;
    // transform: translate(-50%,-50%);
    padding: 30px 24px 30px;
    background: white;
    border-radius: 4px;

    .form_title {
      height: 40px;
      box-sizing: border-box;
      line-height: 40px;
      font-size: 14px;
      color: #303133;
      text-align: left;
    }

    /deep/ .el-input {
      .el-input__inner {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-left: 40px;
      }
      .el-input__icon {
        color: $primary-color;
        font-size: 18px;
      }

      .el-input-group__append {
        background: white;
        color: $primary-color;
        border-radius: 0;
        border-width: 0;
        border-bottom-width: 1px;
      }
    }
  }

  .login_form {
    .company_list {
      height: 304px;
      overflow-y: auto;

      .company_list_item {
        height: 64px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        padding: 12px;
        border: 1px solid rgba(113, 121, 141, 0.08);
        justify-content: space-between;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(36, 40, 50, 0.1);
        }

        + .company_list_item {
          margin-top: 12px;
        }

        > div {
          display: flex;
          align-items: center;

          &:first-child {
            font-size: 14px;
            font-weight: bold;
            color: rgb(36, 40, 50);
          }
          &:last-child {
            font-size: 12px;
            color: rgba(36, 40, 50, 0.4);
          }
        }
      }
    }

    .login_form_msg {
      line-height: 20px;
      height: 40px;
      color: rgba(252, 52, 52, 0.6);
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    /deep/.el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }

  .register_form {
    /deep/ .el-input {
      .el-input__inner {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-left: 10px;
      }
    }
  }

  .login_footer {
    position: absolute;
    bottom: 12px;
    width: 100%;
    text-align: center;
    font-size: 14px;

    * {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
/deep/ .PL10.el-form-item--small .el-form-item__label {
  padding-left: 10px;
}

@media (min-width: 320px) and (max-width: 640px) {
  .login {
    height: 100vh;
    overflow: hidden;

    * {
      box-sizing: border-box;
    }

    .banner {
      left: 5vw;
      top: 70%;
      width: 80vw;
      transform: translate(0, -50%);

      .banner_title {
        color: #ffffff;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.5em;
      }

      .banner_title_desc {
        padding-top: 0.5em;
        padding-bottom: 1.6em;
        font-size: 18px;
        line-height: 1.5em;
        letter-spacing: 0.75em;
      }

      .el-carousel {
        .el-carousel__item {

          > div:first-child {
            font-weight: 400;
            font-size: 18px;
          }

          > div:last-child {
            padding-top: 0.5em;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.75em;
          }
        }

        /deep/.el-carousel__indicators {
          > .el-carousel__indicator {
            padding-top: 0;
            padding-bottom: 0;

            &:not(:first-child) {
              padding-left: 6px;
              padding-right: 6px;
            }
          }
        }
      }
    }

    .login_form,
    .modify_form,
    .register_form {
      position: absolute;
      box-sizing: border-box;
      top: 36%;
      left: 5vw;
      width: 90vw;
      transform: translate(0, -50%);
    }
  }
}
</style>
